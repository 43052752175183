.container-eolo-steps {
  background-color: white;

  p {
    color: #2C3792;
    margin: 20px;
    font-size: 24px;
    text-align: justify;
  }

  .image-text-container {
    margin: 20px 0;
    background-color: #E7F1F2;
  
    .top-content {
      display: flex;
      align-items: center;
      margin-bottom: 20px; /* Ajusta el margen inferior para separar del contenido inferior */
  
      img {
        margin-right: 20px;
        width: 100%; /* Ajusta el tamaño de la imagen aquí */
        height: auto; /* Mantiene la proporción de la imagen */
      }
  
      .text {
        color: #2C3792;
        font-size: 18px;
      }
    }
  
    .bottom-content {
      margin-top: 20px; /* Ajusta el margen superior para separar del contenido superior */
      p {
        color: #2C3792;
        font-size: 18px;
      }
    }
  }

  .oval-card-container {
    position: relative;
    min-height: 400px;  // Aumenta la altura mínima de la tarjeta para que el contenido no se sobreponga
    padding: 20px;      // Agrega padding adicional para más espacio interno
    overflow: visible;  // Permite que los elementos se expandan si es necesario
  }

  .oval-card {
    border-radius: 200px; /* Hace que la tarjeta tenga bordes redondeados */
    overflow: hidden; /* Asegura que el contenido no se salga de los bordes redondeados */
    width: 100%;
    max-width: 1500px; /* Ajusta el tamaño máximo de la tarjeta */
    background-color: #E7F1F2;
    margin-bottom: 20px; /* Añade un margen inferior a cada tarjeta para separarlas */
    position: relative;

    

    .number-circle {
      position: absolute;
      top: -20px;  // Ajusta según sea necesario
      left: 20px;  // Ajusta según sea necesario
      width: 40px;
      height: 40px;
      background-color: #3C9098;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 18px;
      font-weight: bold;
  
      .fa-circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        color: #3C9098;
      }
      
      .number {
        position: relative;
        z-index: 1;
      }

    }

    .card-img-top {
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      width: 100%;
      height: auto;
    }

    .card-body {
      position: relative;
      z-index: 1; 
      padding: 20px;

      .card-text {
        color: #2C3792;
        font-size: 18px;
        text-align: justify; /* Añade justificación al texto */
        padding: 35px 0;
      }

      .title-card {
        color: #3C9098;
        font-size: 24px;
        font-weight: bold;
        // margin-bottom: 5px;
      }
    }
  }
}