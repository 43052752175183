body {
  overflow-x: hidden
}

.common-background {
  padding: 60px 0;
}

.content-container {
  background-color: #F7F7F7;
  text-align: center;
  padding: 20px;
}

.ventilation-types-section {
 
  .section-title {
    background-color: #1B4496;
    color: white;
    padding: 20px;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    border-radius: 8px;
  }

  .section-description {
    color: #29235C;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    text-align: justify;
  }

  
  .objectives-row, .contaminant-row {
    margin-bottom: 2rem;
  }

  .function-card {
    margin-top: 0rem;
    // height: 35%; 
    .card-title {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .card-text {
      font-size: 20px !important;
      text-align: justify;
    }
  }

  .icon-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #008080;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 auto 20px auto;

    .icon-title {
      margin-top: 10px;
      font-size: 1rem;
      text-align: center;
      padding: 0 10px;
    }
  }

 
  .contaminant-block {
    background-color: #B22222;
    padding: 20px;
    border-radius: 8px;
    color: white;
    margin-bottom: 20px;

    .contaminant-header {
      margin-bottom: 20px;
      text-align: center;

      h4 {
        font-size: 1.5rem;
      }

      .contaminant-icons {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        .icon {
          text-align: center;

          p {
            margin-top: 5px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .ventilacion-puntual {
    background-color: #F7F7F7;
  }

  .contaminant-requirement-block {
    .contaminant-requirement {
      background-color: #F7F7F7;
      color: #29235C;
      padding: 20px;
      border-radius: 8px;
      text-align: left;

      h5 {
        color: #352E83;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      p {
        color: #352E83;
        // font-size: 1rem;
        font-size: 20px;
        margin-bottom: 0px;
      }

      .contaminant-images {
        display: flex;
        justify-content: space-between;

        .img-fluid {
          width: 48%;
          border-radius: 8px;
          color: #1B4496;
        }
      }
    }
  }
}
