.container-eolo-system-trajectory{
  background-color: white;


  p{
    color: #2C3792;
    margin: 20px;
    font-size: 24px;
    text-align: justify; /* Justificación aplicada aquí */
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}