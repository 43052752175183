.custom-accordion-header {
  background-color: #1B4496; // Cambia el color de fondo del encabezado
  color: #fff; // Cambia el color del texto del encabezado a blanco
  
  .accordion-button {
    background-color: #1B4496; // Cambia el color de fondo del botón
    color: #fff; // Cambia el color del texto del botón

    &:not(.collapsed) {
      background-color: #1B4496; // Cambia el color de fondo del botón cuando no está colapsado
      color: #fff; // Cambia el color del texto del botón cuando no está colapsado
    }

    &:focus {
      box-shadow: none; // Elimina el borde azul que aparece al enfocar el botón
    }
  }

  h2 {
    color: #fff; // Asegúrate de que el h2 también tenga el color aplicado
  }
}

.objectives-row {
  .function-card {
    background-color: #f8f9fa; // Color de fondo de la tarjeta
    border: 1px solid #dee2e6; // Borde de la tarjeta
    border-radius: 70px; // Bordes redondeados
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Sombra para darle profundidad
    // margin-top: 20px; 
    display: flex;
    flex-direction: column;
    background-color: #E7F1F2;

    .card-body {
      padding: 40px; // Espaciado interno
      flex-grow: 1;
    }

    .card-text {
      font-size: 1.2rem; // Tamaño de fuente
      color: #2B4BA6; // Color del texto
      text-align: justify; // Justificar texto
    }
  }

  .small-image {
    max-width: 200px; // Ajusta el tamaño según tus necesidades
    height: auto;
    margin-bottom: 20px; // Espacio inferior
  }
}