body {
  overflow-x: hidden
}

.custom-paragraph {
  text-align: justify;
  color: #2B2E83;
  font-size: 1.1rem;
}

.img-right {
  // float: right;
  width: 90%; /* Ajusta el ancho según sea necesario */
}