.necessary-eolo-section {
  padding: 60px 0;

  .custom-accordion-header {
    background-color: #1B4496; // Cambia el color de fondo del encabezado
    color: #fff; // Cambia el color del texto del encabezado a blanco
    
    .accordion-button {
      background-color: #1B4496; // Cambia el color de fondo del botón
      color: #fff; // Cambia el color del texto del botón
  
      &:not(.collapsed) {
        background-color: #1B4496; // Cambia el color de fondo del botón cuando no está colapsado
        color: #fff; // Cambia el color del texto del botón cuando no está colapsado
      }
  
      &:focus {
        box-shadow: none; // Elimina el borde azul que aparece al enfocar el botón
      }
    }
  
    h2 {
      color: #fff; // Asegúrate de que el h2 también tenga el color aplicado
    }
  }
  

  .why-eolo-content {
    color: #2c3792;

    h2 {
      color: #2c3792;
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.4rem;
      color: #2c3792;
      text-align: justify;
      margin-bottom: 1rem;
    }
  }

  .section-title {
    color: #2c3792;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .section-description {
    font-size: 1.2rem;
    color: #2c3792;
    // text-align: justify;
    p {
      text-align: justify;
    }
  }

  .new-container {
    background-color: #E7F1F2;
    padding: 50px;
    border-radius: 5px;
    width: 100%;
    margin: 0;

    p {
      font-size: 1.2rem;
      color: #2c3792;
      // text-align: left;
      text-align: justify;
    }
  }

  .small-image {
    max-width: 150px;
    height: auto;
  }
}

// .content-container {
//   padding: 0 !important;
// }

.accordion-body {
  padding: 0 !important;
}
