.small-img {
  width: 100px; /* Ajusta el tamaño según tus necesidades */
  height: auto;
}

.custom-card {
  margin-bottom: 20px; /* Espacio entre las filas */
}

.text-left {
  text-align: left;
}

.small-card {
  max-width: 700px; /* Ajusta el tamaño máximo de la carta */
  margin: 0 auto; /* Centra la carta horizontalmente */
  margin-left: 0;
  background-color: #E7F1F2; /* Color de fondo de la carta */
  color: #3655AA; /* Color del texto */
}

.container-eolo-expansion-options {
  max-width: 700px;
  margin-top: 70px; 
  margin-left: 0;
  h2 {
    color: #24265D;
    font-weight: bold;
  }
  
}

.image-container {
  text-align: right; /* Alinea el contenedor de la imagen a la derecha */
}

.right-image {
  // max-width: 100%;
  // height: auto;
  height: 1200px;
  // width: 1000px;
  object-fit: cover; /* Asegura que la imagen mantenga su proporción */
  margin-left: -150px;
}

@media (max-width: 820px) {
  .right-image {
    width: 100%; /* Asegura que la imagen ocupe el ancho completo de su contenedor */
    height: auto; /* Mantiene las proporciones de la imagen */
    object-fit: cover; /* Asegura que la imagen mantenga su proporción */
    margin-left: 0; /* Remueve el margen negativo en pantallas pequeñas */
  }
}