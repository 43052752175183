.why-eolo-section {
  padding: 40px 0;

  .content-container {
    background-color: rgba(231, 241, 242, 0.9);
    text-align: left;
    padding: 20px;
    max-width: 1285px; /* Limitar el ancho máximo */
    margin: 0 auto; /* Centrar el contenedor horizontalmente */
    border-radius: 8px;
  }

  .white-container {
    background-color: white;
    padding: 20px;
    max-width: 1285px; /* Limitar el ancho máximo */
    margin: 0 auto; /* Centrar el contenedor horizontalmente */
    // border-radius: 8px;
    color: #2c3792;
    font-size: 1.4rem;
    text-align: justify;
  }

  .why-eolo-content {
    color: #2c3792;

    h2 {
      color: #2C3792;
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }

    p {
      font-size: 1.4rem;
      color: #2c3792;
      text-align: justify;
      margin-bottom: 1rem;
    }
  }
}
