footer {
  background-color: #1B4496;
}

footer img {
  margin: 0;
  padding: 0;
}

.footer-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between; /* Esto asegura que Logo1 esté a la izquierda y Logo2 a la derecha */
  }

  img {
    margin: 10px 0; /* Ajusta el margen según sea necesario */

    @media (min-width: 768px) {
      margin: 0 10px;
    }
  }
}

.text-white {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }
}

.text-white .fa-icon {
  margin-right: 8px; /* Ajusta el margen según sea necesario */
}

.icon-bar {
  .text-end {
    text-align: center;

    @media (min-width: 768px) {
      text-align: end;
    }
  }

  img {
    height: 100px;
    margin: 10px 0;

    @media (min-width: 768px) {
      margin: 0;
    }
  }
}
