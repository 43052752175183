/* src/App.scss */

@font-face {
  font-family: 'Avenir LT Pro Medium';
  src: url('../src/assets/fonts/AvenirLTProMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('../src/assets/fonts/Avenir-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('../src/assets/fonts/TTNorms-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Estilos globales */
body {
  font-family: 'Avenir LT Pro Medium', 'Avenir Medium', 'TT Norms', Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

/* Clase para aplicar el fondo */
.common-background {
  background: url('../src/assets/img/wallpaper.svg') no-repeat center center fixed;
  background-size: cover;
  color: white; 
}

/* Estilos adicionales para otros componentes si es necesario */
.header {
  background-color: #343a40;
  color: white;
  padding: 10px 0;
}

.header-title {
  font-size: 1.5rem;
  margin-left: 10px;
}

.navbar-title {
  font-size: 1.5rem;
  color: white;
  text-align: center;
}

/* Nueva clase para un contenedor más grande */
.custom-container {
  max-width: 90%; /* Ajusta este valor según tus necesidades */
  margin: 0 auto; /* Centra el contenedor */
}