body {
  overflow-x: hidden
}

.common-background {
  padding: 60px 0;
}

.content-container {
  background-color: #F7F7F7;
  text-align: center;
  padding: 20px;
}

.custom-container {
  background-color: #EAF0F3; 
  padding: 20px;
  width: 100%;
  height: 100%;
}

.svg-content {
  width: 100%;
  height: auto;
  overflow: hidden;
}


.svg-text {
  font-family: 'TT Norms';
  .svg-link {
    cursor: pointer;
    fill: #5697D1; 
    text-decoration: none; /* Quita el subrayado */
    &:hover, &:focus {
      fill: #5697D1; 
      text-decoration: none; /* Quita el subrayado al pasar el ratón o enfocar */
    }
  }
}

.concepts-definitions-section { 

  .section-title {
    background-color: #1B4496;
    color: white;
    padding: 20px;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    border-radius: 8px;
    cursor: pointer; /* Agrega el cursor pointer */
  }

  .section-description, .ventilation-definition {
    padding: 2% !important;
    color: #29235C;
    // font-size: 1.3rem;
    font-size: 20px;
    margin-bottom: 1.5rem;
    text-align: justify;
  }

  .subsection-title {
    color: #29235C;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    padding: 2% !important;
  }

  .objectives-row, .image-row {
    margin-bottom: 2rem;
  }

  .function-card {
    margin-bottom: 1.5rem;
    .objective-body {
      background-color: #E3EAEC;
    }
  }

  .img-fluid {
    border-radius: 8px;
    
  }

  .vertical-images {
    img {
      width: 100%;
      height: auto;
    }
  }

  .tooltip-button {
    position: absolute;
    background-color: #962E1A;
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    // overflow: hidden; 
  }

  .tooltip-text-left {
    visibility: hidden;
    width: 300px;
    background-color: white;
    color: #2B2E83;
    text-align: justify;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: -310px; /* Ajusta esto según sea necesario para la posición a la izquierda */
    border: 1px solid #26348B;
  }
  
  .tooltip-text-right {
    visibility: hidden;
    width: 300px;
    background-color: white;
    color: #2B2E83;
    text-align: justify;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
    border: 1px solid #26348B;
  }
  
  .tooltip-button:hover .tooltip-text-left,
  .tooltip-button:hover .tooltip-text-right {
    visibility: visible;
  }

  @media (max-width: 768px) {
    .tooltip-text-left, .tooltip-text-right {
      position: fixed; 
      top: 50%; 
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      z-index: 9999; 
      background-color: white;
      padding: 15px;
      border-radius: 8px;
      border: 1px solid #26348B;
      text-align: justify;
    }
  }

  .accordion-header {
    background-color: #1B4496;
    color: white;
    border-radius: 8px;
    .accordion-button {
      background-color: #1B4496;
      color: white;
      &:focus {
        box-shadow: none;
      }
      &::after {
        filter: invert(1);
      }
    }
  }
}


.background-section {
  background-color: #EAF0F3;
  padding: 20px; // Ajusta el padding según tus necesidades
  margin: 0; // Elimina cualquier margen
  h3 {
    color: #312783;
    font-weight: bold;
  }
}

.background-section-exp {
  background-color: #F7F7F7;
  padding: 20px; // Ajusta el padding según tus necesidades
  margin: 0; // Elimina cualquier margen
  h3 {
    color: #312783;
    font-weight: bold;
  }

}

