.top-image {
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.header-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1020; /* Asegúrate de que tenga un z-index suficientemente alto para que se superponga correctamente */
  overflow: visible; 

  .eolo-nav-dropdown .dropdown-toggle::after {
    margin-left: 10px !important; /* Ajusta este valor según lo que necesites */
  }

  .custom-toggle {
    background-color: #1D699A !important; /* Cambia #yourColor por el color que desees */
  }

  .header {
    background-color: #1B4496 !important;
    padding: 1rem 0;

    .logo {
      width: 200px; // Ajusta según sea necesario
      height: auto;
    }

    .header-title {
      font-size: 40px;
      color: #FFFFFF;
    }
  }

  .navbar-dropdown-title {
    font-size: 20px;
  }
  
  .navbar-dropdown-item {
    font-size: 14px;
  }
  

  .full-width-navbar {
    width: 100%;
    background-color: #1D699A !important; 
    padding: 1.5rem 0;
    .navbar-toggler {
      background-color: #FFFFFF;
    }

    .nav-link, .navbar-brand, .navbar-nav .nav-link {
      color: #FFFFFF !important; /* Fuerza el color blanco en los enlaces */
      &:hover {
        color: #FFD700 !important; /* Color al pasar el ratón, ajusta según sea necesario */
      }
    }

    .nav-dropdown .dropdown-menu .dropdown-item {
      color: #FFFFFF !important;
      &:hover {
        color: #FFD700 !important; /* Color al pasar el ratón, ajusta según sea necesario */
      }
    }
  }

  &.navbar-shrink {
    .header {
      padding: 0.5rem 0;

      .logo {
        width: 150px; // Ajusta según sea necesario
      }

      // .header-title {
      //   font-size: 1.6rem; 
      // }
    }

    .full-width-navbar {
      padding: 0.5rem 0;
    }
  }
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .header-container {
    .header {
      padding: 0.5rem 0;

      .logo {
        width: 120px; /* Reducir el tamaño del logo */
        height: auto;
      }

      .header-title {
        font-size: 1.4rem; /* Reducir el tamaño de la fuente del título */
      }
    }

    .full-width-navbar {
      padding: 0.5rem 0; /* Reducir el padding del navbar en móviles */
    }
  }
}

/* Ajustes adicionales para pantallas aún más pequeñas */
@media (max-width: 576px) {
  .header-container {
    .header {
      padding: 0.3rem 0;

      .logo {
        width: 100px; /* Ajustar el logo aún más en pantallas muy pequeñas */
      }

      .header-title {
        font-size: 1.2rem; /* Ajustar el título */
        text-align: center; /* Centrar el texto en pantallas pequeñas */
      }
    }
  }
}
