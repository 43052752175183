body {
  overflow-x: hidden
}

.common-background {
  padding: 60px 0;
}

.content-container {
  background-color: #F7F7F7;
  text-align: center;
  padding: 20px;
}

.ventilation-system-types-section {
  .section-title {
    background-color: #1B4496;
    color: white;
    padding: 20px;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
    border-radius: 8px;
  }

  .subsection-title {
    color: #29235C;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .ventilation-system-row {
    margin-bottom: 2rem;
  }

  .ventilation-system-card {
    margin-top: 1.5rem;
    height: 100%;
    .card-body {
      font-size: 1rem;
      text-align: justify;
    }
  }
}


.image-row {
  margin-bottom: 2rem;
}

.img-fluid {
  // max-height: 500px; 
  width: auto;
}

.header-sistema-ventilacion {
  background-color: #EAF0F3 !important;  
  color: #29235C; 
  
}

.custom-sistema-ventilacion {
  background-color: #EAF0F3; 
  padding: 20px;
  width: 100%;
  height: 100%;
}
