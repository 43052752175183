body{
  overflow-x: hidden
}

.container-content-block-one {
  background-color: #F7F7F7;
  // color: #221B57;
  padding: 20px;
}

.container-content-block-one h2 {
  color: #160E5D;
  font-weight: bold !important;
  font-size: 32px;
  // font-size: 24pt;
}

.container-content-block-one p {
  color: #221B57;
  font-size: 20px;
  text-align: justify;
  margin: 10px;
}

.space-between-containers {
  background-color: #FFFFFF;
  margin-bottom: 20px;
  padding: 20px;
}

.container-content-block-two {
  background-color: #F7F7F7;
  // color: #221B57;
  padding: 20px;

  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    h3 {
      color: #fff;
      font-size: 1.5rem;
    }
    p {
      color: #fff;
      // font-size: 1rem;
    }
  }
  .carousel-item img {
    max-height: 500px; // Ajusta según sea necesario
    object-fit: cover;
  }

}

.container-content-block-two h2 {
  color: #FFFFFF;
  font-weight: bold;
}

.container-content-block-two p {
  color: #FFFFFF;
  font-size: 20px !important;
  text-align: justify;
  margin: 10px;
}

.col-content-block-moho{
  // margin: 10px;
  background-color: #1B4496;
  h2 {
    margin: 10px;
    color: #FFFFFF;
    font-weight: bold;
    // font-size: 24px;
  
  }
}

.small-image {
  width: 100%; // Ajusta el tamaño según tus necesidades
  max-width: 700px; // Ejemplo de tamaño máximo
  height: auto; // Mantiene la proporción de la imagen
}

.container-content-block-three {
  background-color: #F7F7F7;
  padding: 20px;
}

.container-content-block-three h2 {
  color: #29235C;
  font-weight: bold;
}

.container-content-block-three p {
  color: #2B2E83;
  font-size: 24px !important;
  text-align: justify;
  // margin: 20px 10px; 
}

.col-content-block-ventilacion-inteligente {
  margin: 10px 20px; // Ajusta el margen izquierdo y derecho para mayor espacio
  background-color: #E3EAEC;
  padding: 80px;
  position: relative; // Necesario para que los elementos hijos con posición absoluta se posicionen relativamente a este contenedor
}

.highlighted-div {
  background-color: #E3EAEC; // Aplica el color de fondo aquí
  padding: 60px; // Añade algo de padding para espaciar el contenido dentro del div
  border-radius: 5px; // Opcional: añade bordes redondeados
}

.highlighted-div p {
  font-size: 25.5px;
}

.decreto-image {
  width: 350px; // Ajusta el ancho de la imagen según tus necesidades
  height: 400px; // Mantiene la proporción de la imagen
  margin-right: 20px; // Añade un margen derecho para separar la imagen del texto
  float: left;
}

.blue-text {
  border: none;
  color: #FFFFFF;
  background-color: #1C699A;
  border-radius: 50px;
  padding: 15px 30px; // Ajusta el tamaño del botón
  font-size: 18px; // Ajusta el tamaño del texto
  display: block; // Hace que el botón sea un bloque
  margin: 20px auto; // Centra el botón horizontalmente y añade un margen vertical
  text-align: center; // Asegura que el texto esté centrado dentro del botón
}

.unstyled-link {
  color: #5697D1; 
  text-decoration: none; /* Quita el subrayado */
  &:hover, &:focus {
    color: #5697D1; 
    text-decoration: none; /* Quita el subrayado al pasar el ratón o enfocar */
  }
}


